<script lang="ts" setup>
import type { BlogPost } from "@/types/general";
import { mdiCalendarMonthOutline } from "mdi-js-es";

defineProps<{
  item: BlogPost;
}>();

const localeRoute = useLocaleRoute()
</script>

<template>
  <div class="pa-4">
    <NuxtLink :prefetch="false" :to="localeRoute({ name: 'blog-slug', params: { slug: item.route } })" v-if="item.photo">
      <NuxtPicture sizes="md:200px lg:400px" :imgAttrs="{ class: 'rounded-2xl w-full max-h-64' }" :src="item.photo" :alt="item.title" loading="lazy" />
    </NuxtLink>
    <div class="mx-3 mt-3">
      <NuxtLink :prefetch="false" :to="localeRoute({ name: 'blog-slug', params: { slug: item.route } })" class="text-lg font-semibold text-black">
        {{ item.title }}
      </NuxtLink>
    </div>
    <div class="mx-3 mt-2 opacity-50 text-sm">
      <v-icon size="16" class="mr-1 opacity-50">{{ mdiCalendarMonthOutline }}</v-icon>
      {{ item.published_hmn }}
    </div>
  </div>
</template>
